import React from "react";
import googlePlay from "../assets/images/Google.png";
import apple from "../assets/images/Apple.png";
import MorsePhone from "../assets/images/s-phones/mca_half.png";
import mcaWhite from "../assets/images/mca_white.svg";
import instagram from "../assets/images/instagram.svg";
import { ReactSVG } from "react-svg";

export const Footer = () => {
  return (
    <div>
      <section id="download" className="bg-light">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="jumbotron">
                <h1 className="" title="How to Download Morse Code Academy?">
                  How to Download the App
                </h1>
                <div className="line "></div>
                <p className="py-3 ">
                  Morse Code Academy is currently available only on the Play
                  store.
                </p>
                <a
                  className="text-center"
                  href="https://play.google.com/store/apps/details?id=com.seroujkh.morseacademy"
                  target="_blank"
                  title="Download Morse Code Academy From Playstore"
                >
                  <img src={googlePlay} alt=""  className="mx-3"/>
                </a>
                <a
                  className="text-center"
                  href="https://play.google.com/store/apps/details?id=com.seroujkh.morseacademy"
                  target="_blank"
                  title="Download Morse Code Academy From Playstore"
                >
                  <img src={apple} alt=""  className="mx-3"/>
                </a>
              </div>
            </div>
            <div className="col-lg-6 position-relative text-center">
              <img
                src={MorsePhone}
                className="download-img"
                alt="Morse Code Academy App"
                title="Morse Code Academy App"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="footer position-relative">
        <div className="container-fluid py-5 ">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-md-6 my-3">
              <div className="text-center pr-md-5">
                <img src={mcaWhite} className="logo mx-auto" alt="" />
              </div>
              <p className="pr-md-5">
                Morse Code Academy is an Educational Application, that teaches
                users The Morse Code in any language they prefer, and prepares
                them through several practices before rewarding them with a
                certificate.
              </p>
            </div>
            <div className="col-xl-3 my-3 col-md-6">
              <h2>Resources</h2>
              <a href="{{ route('resources') }}">
                <span className="page-links">
                  Practice Text Books{" "}
                  <span className="text-muted">( Coming Soon )</span>
                </span>
              </a>
              <a href="{{ route('resources') }}">
                <span className="page-links">Code Books  <span className="text-muted">( Coming Soon )</span></span>
              </a>
              <a href="{{ route('translate') }}">
                <span className="page-links">Translation </span>
              </a>
              <a href="{{ route('blogs') }}">
                <span className="page-links">Blog  <span className="text-muted">( Coming Soon )</span></span>
              </a>
            </div>
            <div className="col-xl-3 my-3 col-md-6">
              <h2>Keep In Touch</h2>
              <a href="{{ route('contact') }}">
                <span className="page-links">Terms & Conditions</span>
              </a>
             
              <a href="{{ route('privacy-policy') }}">
                <span className="page-links">Privacy Policy</span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.seroujkh.morseacademy"
                target="_blank"
                title="Download Morse Code Academy From Playstore"
              >
                <span className="page-links">Download the App</span>
              </a>
            </div>
            <div className="col-xl-3 my-3 col-md-6">
              <a
                href="https://www.instagram.com/morsecodeacademy/"
                title="Instagram"
                className="footer-insta"
                target="_blank"
              >
                <ReactSVG src={instagram} className=" " />{" "}
                <span>@morsecodeacademy</span>
              </a>
            </div>
            <div className="col-lg-6 col-12 mt-5">
              <div className="greenline"></div>
              <p className="copyright text-center pt-3">
                © 2020 Morse Code Academy Developed by{" "}
                <a href="http://seroujkhandjihanian.com/" target="_blank">
                  Serouj Khandjihanian
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
