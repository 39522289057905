import React from "react";

import HomePhone from "../assets/images/s-phones/p1.png";

export const HomePage = () => {
  return (
    <div>
      <section className="heading-section">
        <div className="container h-100 ">
          <div className="row d-flex align-items-center h-100 row-100">
            <div className="col-12 order-1 d-xl-none d-block">
              <h1 className="" title="Learn Morse Code">
                Are You Ready To Learn The Morse Code ?
              </h1>
            </div>
            <div className="col-xl-6 col-sm-12  align-self-center order-3 order-xl-1">
              <div>
                <h1 className="d-xl-block d-none" title="Learn Morse Code">
                  Are You Ready To Learn The Morse Code ?
                </h1>
                <p className="pt-3">
                  <a
                    href="{{ route('home')}}"
                    className="s-link"
                    title="Morse Code Academy"
                  >
                    {" "}
                    Morse Code Academy
                  </a>{" "}
                  is an Educational Application, that teaches users The Morse
                  Code in any language they prefer, and prepares them through
                  several practices before rewarding them with a certificate.
                </p>
                <p className="py-4">
                  <a
                    href="{{ route('home')}}"
                    className="s-link"
                    title="Morse Code Academy"
                  >
                    {" "}
                    Morse Code Academy
                  </a>{" "}
                  has a web interface, where users can translate between
                  serveral languages of the morse code, Read Morse Blogs,
                  Download Worksheets and many more.
                </p>
                <ul className="py-4 text-center">
                  <li className="mb-sm-0 mb-2">
                    <a
                      href="#download"
                      className="btn-white "
                      title="Download Morse Code Academy"
                    >
                      <span>Download Now</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#features"
                      className="btn-dark-blue ml-0 ml-sm-3"
                      title="See Morse Code Academy App Features"
                    >
                      <span>App Features</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 text-center position-relative align-self-xl-center align-self-lg-end py-xl-0 py-4 overflow-hidden order-2 order-xl-2">
              <img
                src={HomePhone}
                alt=""
                className="main-phone"
                title="Telegram"
              />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-heading  " title="Morse Code Features">
                App Features
              </h1>
              <h1 className="section-subheading py-3 ">
                "Try and Leave this World a litter Better Than You Found It."
              </h1>
            </div>
          </div>

          <div className="row py-md-5 features-circles justify-content-center">
            <div className=" my-3 text-center col-lg-4 col-md-6 col-6 ">
              <div className="circle-box ">
                <span>
                  <i className="fas fa-award "></i>
                </span>
              </div>
              <h1
                className="pt-3"
                title="Get Certified From Morse Code Academy"
              >
                Certificate
              </h1>
              <p className="py-3 ">
                Morse Code Academy rewards it's users with a certificate once
                the user has completed all the practices.
              </p>
            </div>
            <div className=" my-3 text-center col-lg-4 col-md-6 col-6 ">
              <div className="circle-box ">
                <span>
                  <i className="fas fa-sort-alpha-down-alt"></i>
                </span>
              </div>
              <h1
                className="pt-3"
                title="Morse Code Academy has several Languages"
              >
                Languages
              </h1>
              <p className="py-3">
                Morse Code Academy app can successfully teach the morse code in
                6 languages which are : English, Armenian, Arabic, Spanish,
                French,Russian.
              </p>
            </div>

            <div className=" my-3 text-center col-lg-4 col-md-6 col-6">
              <div className="circle-box ">
                <span>
                  <i className="far fa-calendar-alt"></i>
                </span>
              </div>
              <h1 className="pt-3" title="Morse Code  has Daily Challenges">
                Daily Challenges
              </h1>
              <p className="py-3">
                Morse Code Academy has Daily Challenges where the user has to
                decode a message that is given in morse code. The Message is
                mostly a Motivation Quote.
              </p>
            </div>

            <div className=" my-3 text-center col-lg-4 col-md-6 col-6">
              <div className="circle-box ">
                <span>
                  <i className="fas fa-book"></i>
                </span>
              </div>
              <h1 className="pt-3" title="Morse Code Books">
                CodeBook
              </h1>
              <p className="py-3">
                Morse Code Academy has CodeBooks for each Language, that covers
                the easiest way to learn the morse codeand includes practices.
              </p>
            </div>
            <div className=" my-3 text-center col-lg-4 col-md-6 col-6">
              <div className="circle-box ">
                <span>
                  <i className="fas fa-graduation-cap"></i>
                </span>
              </div>
              <h1
                className="pt-3"
                title="Practice the Morse Code as much as you want"
              >
                Unlimitted Practices
              </h1>
              <p className="py-3">
                Morse Code Academy has Unlimitted Practices. Users can practice
                as much they want, in different categories ( Letters, Numbers,
                Sentences..)
              </p>
            </div>

            <div className=" my-3 text-center col-lg-4 col-md-6 col-6">
              <div className="circle-box ">
                <span>
                  <i className="fas fa-globe-asia"></i>
                </span>
              </div>
              <h1 className="pt-3" title="Translate Morse Code">
                Active Translation
              </h1>
              <p className="py-3">
                Morse Code Academy has a feature that the users can output their
                Morse Code via the Flash Light or Sound Audio or Text.
              </p>
            </div>
          </div>

          <div className="swiper-container phone-swiper">
            <div className="swiper-wrapper">
              {/* <?php for ($i = 1; $i <= 13; $i++) {
                        echo '<div className="swiper-slide" ><img src="assets/images/s-phones/p' . $i
                        . '.png" alt=""></div>' ; } ?> </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-light py-5 latest-blogs">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center mb-5">
                    <h1 className="section-heading  "  title="Morse Code Articles">Latest Blogs</h1>
                </div>

                {/* <?php $latestposts = App\Post::latest()
                ->take(3)
                ->get(); ?>
                @foreach ($latestposts as $post)
                    <div className="col-12 text-center mb-3" >
                        <a className="lnews-box px-3 py-2 d-block text-white"
                            href="{{ route('blog.single', ['slug' => $post->slug]) }}">
                            <h1 className=" b-title" title="{ $post->name }} ">{{ $post->name }} </h1>
                            <span><i className="fas fa-angle-double-right"></i></span>
                        </a>
                    </div>

                @endforeach 

                <div className="col-12 mt-4 text-center" >
                    <a href="{{ route('blogs') }}" className="btn-white bg-green">
                        <span className="text-white">See All Blogs</span>
                    </a>
                </div>
            </div>
        </div>
    </section> 

    <section className="py-5 extras">
        <div className="container">
            <div className="row">
                <div className="col-lg-6  my-3" >
                    <h1 className="section-heading  ">Resources</h1>
                    <div className="line"></div>
                    <p className="my-3">
                    <a href="{{route('home') }}" className="s-link" title="Learn Morse Code">Morse Code Academy
                        website has a Resource </a>section, where you can download Morse Code Books in any prefered Language.
                        Workbook will be available soon.</p>
                    <a href="{{ route('resources')}}" className="btn-dark-blue" title="Morse Code Resources" >
                        <span>Check It Out</span>
                    </a>
                </div>


                <div className="col-lg-6  my-3" >
                    <h1 className="section-heading  ">Translation</h1>
                    <div className="line"></div>
                <p className="my-3"> <a href="{{ route('home')}}" className="s-link" title="Translate Morse Code" >Morse Code Academy website </a>has a translate section ,
                        where users can encode and decode messages between different languages and morse code.</p>
                <a href="{{ route('translate')}}" title="Translate Morse Code"  className="btn-dark-blue">
                        <span>Check It Out</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
*/}
    </div>
  );
};
