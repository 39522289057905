import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Footer, Header } from "./components";
import { HomePage, PrivacyPolicy, TermsConditions } from "./Pages";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsConditions />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <h1>Error</h1>,
  },
]);
function App() {
  return (
    <div className="App">
      <Header />
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
      <Footer />
    </div>
  );
}

export default App;
