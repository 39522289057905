import React from "react";
import { Banner } from "../components";

export const PrivacyPolicy = () => {
  return (
    <div>
      <Banner
        title={"Privacy And Policy "}
        subtitle={
          ".--. .-. .. ...- .- -.-. -.-- / .- -. -.. / .--. --- .-.. .. -.-. -.--"
        }
      />

      <div className="container py-5">
        <div className="row py-lg-4 text-left">
          <div className="col-12 pb-5">
            <h1 className="text-center show">PRIVACY POLICY</h1>
          </div>
          <div className="col-12 mt-5 show">
            <h2 className="grid-title show">ABOUT</h2>
            <p className="fs-20 show">
              Serouj Khandjihanian built the Morse Code Academy app as a Free app. This
              SERVICE is provided by Serouj Khandjihanian at no cost and is
              intended for use as is. This page is used to inform visitors
              regarding my policies with the collection, use, and disclosure of
              Personal Information if anyone decided to use my Service. If you
              choose to use my Service, then you agree to the collection and use
              of information in relation to this policy. The Personal
              Information that I collect is used for providing and improving the
              Service. I will not use or share your information with anyone
              except as described in this Privacy Policy. The terms used in this
              Privacy Policy have the same meanings as in our Terms and
              Conditions, which is accessible at Morse Code Academy unless otherwise
              defined in this Privacy Policy.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">LOG DATA</h2>
            <p className="fs-20 show">
              I want to inform you that whenever you use my Service, in a case
              of an error in the app I collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing my Service, the time and
              date of your use of the Service, and other statistics.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">INFORMATION COLLECTION AND USE</h2>
            <p className="fs-20 show">
              For a better experience, while using our Service, I may require
              you to provide us with certain personally identifiable
              information, including but not limited to
              email,name,fullname,biography,links,. The information that I
              request will be retained on your device and is not collected by me
              in any way. The app does use third party services that may collect
              information used to identify you. Link to privacy policy of third
              party service providers used by the app
            </p>
            <ul className="blue-bullet py-3">
              <li className="show">
                <a
                  href="https://www.google.com/policies/privacy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fs-20">Google Play Services</span>
                </a>
              </li>
              <li className="show">
                <a
                  href="https://support.google.com/admob/answer/6128543?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fs-20">AdMob</span>
                </a>
              </li>
              <li className="show">
                <a
                  href="https://www.facebook.com/about/privacy/update/printable"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fs-20">Facebook</span>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="col-12 mt-5">
            <h2 className="grid-title show">COOKIES</h2>
            <p className="fs-20 show">
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory. This Service does not use these
              “cookies” explicitly. However, the app may use third party code
              and libraries that use “cookies” to collect information and
              improve their services. You have the option to either accept or
              refuse these cookies and know when a cookie is being sent to your
              device. If you choose to refuse our cookies, you may not be able
              to use some portions of this Service.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">SERVICE PROVIDERS</h2>
            <p className="fs-20 show">
              I may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <ul className="blue-bullet py-3">
              <li className="show">
                <span className="fs-20">To facilitate our Service</span>
              </li>
              <li className="show">
                <span className="fs-20">
                  To provide the Service on our behalf
                </span>
              </li>
              <li className="show">
                <span className="fs-20">
                  To perform Service-related services
                </span>
              </li>
              <li className="show">
                <span className="fs-20">
                  To assist us in analyzing how our Service is used
                </span>
              </li>
            </ul>
            <p className="fs-20 show">
              I want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">SECURITY</h2>
            <p className="fs-20 show">
              I value your trust in providing us your Personal Information, thus
              we are striving to use commercially acceptable means of protecting
              it. But remember that no method of transmission over the internet,
              or method of electronic storage is 100% secure and reliable, and I
              cannot guarantee its absolute security.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">LINKS TO OTHER SITES</h2>
            <p className="fs-20 show">
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by me. Therefore, I strongly
              advise you to review the Privacy Policy of these websites. I have
              no control over and assume no responsibility for the content,
              privacy policies, or practices of any third-party sites or
              services.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">CHILDREN'S PRIVACY</h2>
            <p className="fs-20 show">
              These Services do not address anyone under the age of 13. I do not
              knowingly collect personally identifiable information from
              children under 13 years of age. In the case I discover that a
              child under 13 has provided me with personal information, I
              immediately delete this from our servers. If you are a parent or
              guardian and you are aware that your child has provided us with
              personal information, please contact me so that I will be able to
              do necessary actions.
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">CHANGES TO THIS PRIVACY POLICY</h2>
            <p className="fs-20 show">
              I may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. I will
              notify you of any changes by posting the new Privacy Policy on
              this page. This policy is effective as of 2021-04-14
            </p>
          </div>

          <div className="col-12 mt-5">
            <h2 className="grid-title show">CONTACT US</h2>
            <p className="fs-20 show">
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact me at{" "}
              <a href="mailto:seroujkh.dev@gmail.com">seroujkh.dev@gmail.com</a>.
            </p>
            <p className="fs-20 show">
              his privacy policy page was created at{" "}
              <a
                href="https://privacypolicytemplate.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacypolicytemplate.net{" "}
              </a>
              and modified/generated by{" "}
              <a
                href="https://app-privacy-policy-generator.nisrulz.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                App Privacy Policy Generator
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
