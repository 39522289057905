import React from "react";

export const Banner = (props: { title: string; subtitle: string }) => {
  return (
    <section className=" bg-theme ">
      <div className="container banner d-flex align-items-center position-relative">
        <div className="row pb-5">
          <div className="col-12 text-left pb-5">
            <h2 className="fw-bold text-white pb-2">{props.title}</h2>
            <h4 className="fw-bold text-white pb-5 ">{props.subtitle}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};
